import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import * as styles from "../../styles/pages/FEMPage.module.scss";

import CardBrowser from "../../components/CardBrowser/CardBrowser";
import { IconLabel, DifficultyLabel } from "../../components/Label/Label";
import SearchBar from "../../components/SearchBar/SearchBar";
import TechStackJSON from "../../data/tech/techInfo.json";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

const Frontendmentor = () => {
  const [query, setQuery] = useState("");
  const [filteredList, setFilteredList] = useState([]);

  const data = useStaticQuery(graphql`
    query getSolutionAndChallengeInfo {
      allMdx(filter: { fileAbsolutePath: { regex: "content/fem/" }, frontmatter: {} }) {
        edges {
          node {
            id
            frontmatter {
              approaches
              challengeId
              challengeTrack
              description
              liveLink
              slug
              stack
              title
            }
          }
        }
      }
      allFemChallengeInfoJson {
        edges {
          node {
            jsonId
            title
            difficulty
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  const allSolutions = getMergedSolutionInfo(data);

  function onChangeHandler(e) {
    const query = e.target.value.toLowerCase();
    setQuery(query);

    let updatedList = allSolutions.filter((solution) => {
      if (
        solution.challengeTitle.toLowerCase().includes(query) ||
        solution.challengeTrack.toLowerCase().includes(query) ||
        solution.stackCombinedString.includes(query) ||
        solution.approachesCombinedString.includes(query)
      ) {
        return true;
      }
      return false;
    });
    setFilteredList(updatedList);
  }

  const crumbs = {
    trail: [
      {
        name: "All Works",
        url: "/work",
      },
    ],
    current: {
      name: "Frontend Mentor Challenge",
    },
  };

  return (
    <div className={styles.content_wrapper}>
      <header className={styles.header}>
        <Breadcrumb config={crumbs} />
        <div style={{ textAlign: "center" }}>
          <h1>Daily Frontend Mentor Challenge</h1>
          <p>
            Frontendmentor is a platform that provides front-end challenges to solve. Challenges are
            categories into different levels of difficulties (newbie, junior, intermediate,
            advanced, and guru). Each challenge are provided with a set of resources (style guide,
            optimized images ) to kickstart the process.
          </p>
        </div>
      </header>

      <div className={styles.searchbar_wrapper}>
        <SearchBar onChangeHandler={onChangeHandler} />
      </div>

      <Listing query={query} filteredList={filteredList} allSolutions={allSolutions} />
    </div>
  );
};

export default Frontendmentor;

const Listing = ({ query, filteredList, allSolutions }) => {
  return (
    <section className={styles.challenges}>
      {query && filteredList.length > 0 ? (
        <ul className={styles.challenge_grid}>{filteredList.map(CardSolution)}</ul>
      ) : query && filteredList.length < 1 ? (
        <p>Sorry, nothing matched that search.</p>
      ) : (
        <ul className={styles.challenge_grid}>{allSolutions.map(CardSolution)}</ul>
      )}
    </section>
  );
};

const getFrontmatterFromMDX = (data) => {
  const allMdx = data.allMdx.edges;
  const allFrontmatter = allMdx.map((mdx) => {
    return {
      id: mdx.node.id,
      slug: mdx.node.frontmatter.slug,
      challengeTrack: mdx.node.frontmatter.challengeTrack,
      challengeId: mdx.node.frontmatter.challengeId,
      stack: mdx.node.frontmatter.stack,
      approaches: mdx.node.frontmatter.approaches,
      liveLink: mdx.node.frontmatter.liveLink,
      description: mdx.node.frontmatter.description,
      stackCombinedString: mdx.node.frontmatter.stack.join("").toLowerCase(),
      approachesCombinedString: mdx.node.frontmatter.approaches.join("").toLowerCase(),
    };
  });

  return allFrontmatter;
};

const getAllFemChallengeInfo = (data) => {
  return data.allFemChallengeInfoJson.edges;
};

const mergeFrontmatterWithChallengeInfo = (allSolutionsFrontmatter, allFEMChallenge) => {
  allSolutionsFrontmatter.forEach((solution) => {
    const matchedChallenge = allFEMChallenge.filter(
      (challenge) => challenge.node.jsonId === solution.challengeId
    )[0];

    solution["gatsbyImageData"] = matchedChallenge.node.image.childImageSharp;
    solution["challengeTitle"] = matchedChallenge.node.title;
    solution["challengeDifficulty"] = matchedChallenge.node.difficulty;
  });

  return allSolutionsFrontmatter;
};

const getMergedSolutionInfo = (data) => {
  const allSolutionsFrontmatter = getFrontmatterFromMDX(data);
  const allFEMChallenge = getAllFemChallengeInfo(data);
  const updatedSolutionsInfo = mergeFrontmatterWithChallengeInfo(
    allSolutionsFrontmatter,
    allFEMChallenge
  );
  return updatedSolutionsInfo;
};

const TechItemList = ({ tech }) => {
  const matchedTech = TechStackJSON.find((obj) => obj.name === tech.toLowerCase());
  const { displayTitle, color } = matchedTech;

  return <li style={{ color: color }}>{displayTitle}</li>;
};

const CardSolution = (solution) => {
  const {
    id,
    slug,
    gatsbyImageData,
    challengeTitle,
    challengeTrack,
    stack,
    challengeDifficulty,
    description,
  } = solution;
  return (
    <li key={id}>
      <article>
        <Link to={slug}>
          <CardBrowser
            hasHoverInteraction
            showShadow
            featuredImg={gatsbyImageData}
            alt={id}
            label={<IconLabel label={challengeTrack} />}
          ></CardBrowser>
        </Link>

        <section className={styles.info_wrapper}>
          <h3>{challengeTitle}</h3>

          <div className={styles.info_text_wrapper}>
            <DifficultyLabel difficulty={challengeDifficulty} />
            <ul className={styles.info_tech_wrapper}>
              {stack.map((i) => (
                <TechItemList tech={i} key={i} />
              ))}
            </ul>
            <p>{description}</p>
          </div>
        </section>
      </article>
    </li>
  );
};
