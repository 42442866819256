import * as React from "react";
import * as styles from "./SearchBar.module.scss";

const SearchBar = ({ onChangeHandler }) => {
  return (
    <>
      <label className={styles.searchbar_label} htmlFor="fem-search">
        Seach FEM Challenges
      </label>
      <input
        type="search"
        id="fem-search"
        placeholder="Search for a tech..."
        onChange={onChangeHandler}
        className={styles.searchbar_input}
      />
    </>
  );
};

export default SearchBar;
